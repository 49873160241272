import { plansorchestrator } from '@tabeo/platform-api-client'
import { Attributes, Modal, Panel } from '@tabeo/scarf'
import { Button } from '@tabeo/scarf2'
import moment from 'moment'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import RegisterClaimModal from './RegisterClaimModal'

type BenefitsPanelProps = {
  data?: plansorchestrator.ModelsGetSubscriptionsSubscription
}

export default function BenefitsPanel({ data }: BenefitsPanelProps) {
  const { t } = useTranslation()
  const modal = useRef<Modal>()

  if (data?.status && ['new', 'sent', 'sign_up'].includes(data?.status)) {
    return null
  }

  const benefits = [
    data?.treatments?.map(({ treatment, usages }) => ({
      name: treatment?.name,
      quantity: treatment?.quantity,
      used: usages?.reduce((acc, curr) => acc + (curr?.quantity || 0), 0),
    })),
    data?.perks?.map(({ perk, usages }) => ({
      name: perk?.name,
      quantity: perk?.quantity,
      used: usages?.reduce((acc, curr) => acc + (curr?.quantity || 0), 0),
    })),
  ]
    .flat()
    .filter(Boolean) as {
    name: string
    quantity?: number
    used: number
  }[]

  return (
    <>
      <RegisterClaimModal ref={modal} />
      <Panel
        header={
          <>
            <Panel.Title>{t('Included goods/services')}</Panel.Title>
            {data?.status !== 'expired' && (
              <Button
                variant="primary"
                size="sm"
                onClick={() => modal.current?.open()}
              >
                {t('Register claim')}
              </Button>
            )}
          </>
        }
        data-testid="benefits-panel"
      >
        <div className="p-5">
          <Attributes
            sections={[
              benefits.map(b => ({
                label: b.name,
                value:
                  b.quantity === undefined
                    ? t('Unlimited')
                    : t(
                        `{{totalClaimCount}} ({{remainingClaimCount}} left to claim in this membership year)`,
                        {
                          totalClaimCount: b.quantity,
                          remainingClaimCount: b.quantity - b.used,
                        }
                      ),
              })),
            ]}
          />
          <p className="caption mt-4 text-tabeo-ink-2">
            {t(
              'The counters above will reset after the membership renewal on {{date}}.',
              { date: moment(data?.renewalDate).format('DD MMM YYYY') }
            )}
          </p>
        </div>
      </Panel>
    </>
  )
}
