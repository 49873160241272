import { GridTableCell as Cell, GridTableRow, themeGet } from '@tabeo/scarf'
import ScarfProps from '@tabeo/scarf/dist/types/ScarfProps'
import { format } from '@tabeo/sharpei/utils/currency'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { plansorchestrator } from '@tabeo/platform-api-client'
import { useTranslation } from 'react-i18next'
import SubscriptionV2StateTag from 'components/SubscriptionV2StateTag'

const Row = styled(GridTableRow)<ScarfProps>`
  & > * {
    border-top: 1px solid ${themeGet('colors.sky.1')};
  }
  &:hover > * {
    background: ${themeGet('colors.sky.3', 'white')};
  }
`

export type ListItemProps = {
  data: plansorchestrator.ModelsSelectSubscriptionAggregate
  fields: Record<string, boolean>
}

function formatPatient(
  patient: plansorchestrator.ModelsSelectSubscriptionsPatient | undefined,
  customer: plansorchestrator.ModelsSelectSubscriptionsCustomer | undefined
) {
  if (patient?.firstName || patient?.lastName) {
    return (
      <>
        {patient?.firstName} {patient?.lastName}
      </>
    )
  }

  return customer?.email || 'N/A'
}

const ListItem = ({ data, fields }: ListItemProps) => {
  const { t } = useTranslation()

  return (
    <Row bg="white" as={Link} to={`/subscriptions/${data.subscription?.id}`}>
      {fields.displayId && (
        <Cell color="ink.2">{data.subscription?.displayId}</Cell>
      )}
      {fields.patient && (
        <Cell>{formatPatient(data.patient, data.customer)}</Cell>
      )}
      {fields.plan && <Cell color="ink.2">{data?.plan?.name}</Cell>}
      {fields.price && (
        <Cell textAlign="right" color="ink.2">
          {t('{{amount}} / month', { amount: format(data.plan?.price) })}
        </Cell>
      )}
      {fields.status && (
        <Cell>
          <SubscriptionV2StateTag
            size="sm"
            status={data.subscription?.status}
          />
        </Cell>
      )}
    </Row>
  )
}

export default ListItem
