import { plansorchestrator } from '@tabeo/platform-api-client'
import { Icons, Panel, Switch } from '@tabeo/scarf'
import { Button, TextInput, toast, Tooltip } from '@tabeo/scarf2'
import config from 'config'
import { useTranslation } from 'react-i18next'
import { usePlanV2 } from 'resources/PlanV2'

type SharingProps = {
  data?: plansorchestrator.ModelsPlan
}

function Sharing({ data }: SharingProps) {
  const { t } = useTranslation()
  const { resource } = usePlanV2()

  const publicUrl = `${config.CONSUMER_APP_URL}/plans/${data?.id}`

  // eslint-disable-next-line no-underscore-dangle
  const isPrivate = data?._private

  return (
    <Panel
      header={
        <div className="flex">
          <Panel.Title>{t('Sharing')}</Panel.Title>
          <Tooltip
            title={t(
              'Turning this off will prevent new members from signing up. Existing members will not be affected.'
            )}
          >
            <Icons.Questionmark />
          </Tooltip>
        </div>
      }
    >
      <div className="flex items-center justify-between px-5 py-4">
        <label className="text-tabeo-ink-0" htmlFor="privateSwitch">
          {t('Allow new members')}
        </label>
        <Switch
          key={isPrivate ? 'private' : 'public'}
          id="privateSwitch"
          defaultValue={!isPrivate}
          input={{
            onChange: async (v: boolean) => {
              await resource?.update({ _private: !v })
            },
          }}
        />
      </div>
      {!isPrivate && (
        <div className="border-t">
          <div className="mx-5 my-4 space-y-2.5">
            <h4 className="subheading text-tabeo-ink-2">
              {t('Shareable link')}
            </h4>
            <TextInput value={publicUrl} readOnly />
            <div className="grid grid-cols-2 gap-2.5">
              <Button
                variant="secondary"
                size="sm"
                onClick={async () => {
                  await navigator.clipboard.writeText(publicUrl)
                  toast.success(t('Link has been copied'))
                }}
              >
                Copy link
              </Button>
            </div>
            <div className="caption text-tabeo-ink-2">
              {t('Anyone with the link will be able to sign up.')}
            </div>
          </div>
        </div>
      )}
    </Panel>
  )
}

export default Sharing
