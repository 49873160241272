import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import { ReactComponent as ClockYellow } from 'components/checkout-payment/assets/clock-yellow.svg'
import { useTranslation } from 'react-i18next'
import CancelButton from '../CancelButton'

export default function New() {
  const { t } = useTranslation()

  return (
    <ActionCard>
      <ActionCard.Header>
        <ClockYellow className="h-6 w-6" />
        {t('New member')}
      </ActionCard.Header>
      <ActionCard.Body>
        {t(
          'This member has started joining a plan. More of their details will be added as they complete the sign up process.'
        )}
      </ActionCard.Body>
      <ActionCard.Actions>
        <CancelButton />
      </ActionCard.Actions>
    </ActionCard>
  )
}
