import { plansorchestrator } from '@tabeo/platform-api-client'
import Resource, { createResourceHook } from '@tabeo/resync'
import { useRouteMatch } from 'react-router-dom'
import api from 'utils/platform-api'
import { useVendor } from './Vendor'

interface Params {
  id: string
  vendorId: string
}

class PlanV2 extends Resource<
  plansorchestrator.ModelsPlan & {
    numberOfActiveSubscriptions?: number
  },
  Params
> {
  async fetch() {
    const response =
      await api.plansorchestrator.plans.apiV1OrchestratorPlansPlansPlanIdGet({
        vendorId: this.params.vendorId,
        planId: this.params.id,
      })

    this.data = response.plan
      ? {
          ...response.plan,
          numberOfActiveSubscriptions: response.activeSubscriptionsNumber,
        }
      : response.plan

    return this.data
  }

  deletePlan = async () => {
    await api.plansorchestrator.plans.apiV1OrchestratorPlansPlansPlanIdDelete({
      planId: this.params.id,
    })
  }

  update = async (payload: plansorchestrator.ModelsUpdatePlanRequest) => {
    await api.plansorchestrator.plans.apiV1OrchestratorPlansPlansPlanIdPatchRaw(
      {
        planId: this.params.id,
        body: payload,
      }
    )

    await this.fetch()
  }
}

export default PlanV2
export const useResource = createResourceHook(PlanV2)
export function usePlanV2(p?: Partial<Params> | (() => Partial<Params>)) {
  const { data: vendor } = useVendor()

  const match = useRouteMatch<{ planId?: string }>({
    path: ['/plans/:planId', '/plans/:planId/edit'],
  })

  let params: Partial<Params> | undefined
  if (p instanceof Function) {
    params = p()
  } else {
    params = p
  }

  const {
    params: { planId },
  } = match || { params: {} }

  const planIdParam = params?.id || planId
  const vendorIdParam = params?.vendorId || vendor?.id

  return useResource(() => {
    if (!planIdParam) {
      throw new Error('Plan ID is null')
    }

    if (!vendorIdParam) {
      throw new Error('Vendor ID is null')
    }

    return {
      id: planIdParam,
      vendorId: vendorIdParam,
    }
  })
}
