import parseQuery from '@tabeo/sharpei/utils/parseQuery'

const getEnvironment = () => {
  const key = process.env.REACT_APP_API_ENV || ''

  switch (key) {
    case 'master':
    case 'production':
      return 'production'
    case 'playground':
    case 'demo':
      return 'demo'
    case 'staging':
      return 'staging'
    case 'local':
      return 'local'
    default:
      return 'development'
  }
}

const geDatoUrl = env => {
  switch (env) {
    case 'production':
      return `https://graphql.datocms.com`
    case 'staging':
      return `https://graphql.datocms.com/preview`
    case 'demo':
      return `https://graphql.datocms.com`
    case 'local':
      return `https://graphql.datocms.com/preview`
    default:
      return 'https://graphql.datocms.com/preview'
  }
}

export function getLanguage() {
  const { lng } = parseQuery(window.location.search)
  if (lng) {
    window.localStorage.setItem('lng', lng)
    return lng
  }

  const localLng = window.localStorage.getItem('lng', lng)
  if (localLng) {
    return localLng
  }

  if (window.location.hostname.endsWith('.co.uk')) {
    return 'en-GB'
  }

  if (window.location.hostname.endsWith('.nl')) {
    return 'nl-NL'
  }

  if (window.location.hostname.endsWith('.it')) {
    return 'it-IT'
  }

  if (window.location.hostname.endsWith('.fr')) {
    return 'fr-FR'
  }

  if (window.location.hostname.endsWith('.es')) {
    return 'es-ES'
  }

  if (window.location.hostname.endsWith('.eu')) {
    return 'de-DE'
  }

  return 'en-GB'
}

export function getCountryCode(env) {
  const { countryCode } = parseQuery(window.location.search)
  if (env !== 'production' && countryCode) {
    window.localStorage.setItem('countryCode', countryCode)
    return countryCode
  }

  const localCountryCode = window.localStorage.getItem(
    'countryCode',
    countryCode
  )
  if (env !== 'production' && localCountryCode) {
    return localCountryCode
  }

  if (window.location.hostname.endsWith('.co.uk')) {
    return 'gb'
  }

  if (window.location.hostname.endsWith('.nl')) {
    return 'nl'
  }

  if (window.location.hostname.endsWith('.it')) {
    return 'it'
  }

  if (window.location.hostname.endsWith('.fr')) {
    return 'fr'
  }

  return 'gb'
}

const env = getEnvironment()
const countryCode = getCountryCode(env)

export function getCurrency(countryCode) {
  const currencies = {
    gb: 'gbp',
    nl: 'eur',
    it: 'eur',
    fr: 'eur',
    es: 'eur',
    de: 'eur',
  }
  return currencies[countryCode] || 'gbp'
}

export function getAppUrlForCountry(app, country) {
  const host = `${app ? `${app}.` : ''}tabeo`

  const domains = {
    de: '.eu',
    es: '.es',
    fr: '.fr',
    it: '.it',
    nl: '.nl',
    gb: '.co.uk',
  }

  switch (env) {
    case 'production':
      return `https://${host}${domains[country]}`
    case 'demo':
      return `https://demo.${host}${domains[country]}`
    case 'staging':
      return `https://staging.${host}${domains[country]}`
    default:
      return `https://development.${host}${domains[country]}`
  }
}

export const getStripeKey = () => {
  const env = getEnvironment()
  const countryCode = getCountryCode()
  const keys = {
    production: {
      gb: 'pk_live_q0Z92g8ypGCZ9TRp1ikYrJPz',
      nl: 'pk_live_q0Z92g8ypGCZ9TRp1ikYrJPz',
      fr: 'pk_live_51JMAZQBGIOYu7H52XkREoh7xQuk2kMZv5EhQ9gdxQYa3NoD4x0H4TVLdnq8E2nLZSccisYVnsC9WanYQyEFX6MVw00k8CoB2Iy',
      it: 'pk_live_51JMAZQBGIOYu7H52XkREoh7xQuk2kMZv5EhQ9gdxQYa3NoD4x0H4TVLdnq8E2nLZSccisYVnsC9WanYQyEFX6MVw00k8CoB2Iy',
      es: 'pk_live_51JMAZQBGIOYu7H52XkREoh7xQuk2kMZv5EhQ9gdxQYa3NoD4x0H4TVLdnq8E2nLZSccisYVnsC9WanYQyEFX6MVw00k8CoB2Iy',
      de: 'pk_live_51JMAZQBGIOYu7H52XkREoh7xQuk2kMZv5EhQ9gdxQYa3NoD4x0H4TVLdnq8E2nLZSccisYVnsC9WanYQyEFX6MVw00k8CoB2Iy',
    },
    demo: {
      gb: 'pk_test_waNNA0J3yUXiBnsRvjf9Cktt',
      nl: 'pk_test_waNNA0J3yUXiBnsRvjf9Cktt',
      fr: 'pk_test_51JRbpaHF0Yp4cpo6kfd2i22RfCzZ27ZRIbHsjsawUnWqEoxx2kIHGzrgTtMvh6jOHDSXhnw9IKzMCqimttIfX2W900LinBso43',
      it: 'pk_test_51JRbpaHF0Yp4cpo6kfd2i22RfCzZ27ZRIbHsjsawUnWqEoxx2kIHGzrgTtMvh6jOHDSXhnw9IKzMCqimttIfX2W900LinBso43',
      es: 'pk_test_51JRbpaHF0Yp4cpo6kfd2i22RfCzZ27ZRIbHsjsawUnWqEoxx2kIHGzrgTtMvh6jOHDSXhnw9IKzMCqimttIfX2W900LinBso43',
      de: 'pk_test_51JRbpaHF0Yp4cpo6kfd2i22RfCzZ27ZRIbHsjsawUnWqEoxx2kIHGzrgTtMvh6jOHDSXhnw9IKzMCqimttIfX2W900LinBso43',
    },
    staging: {
      gb: 'pk_test_TOSV7Y0lyJd50zQTbVoKkOXK',
      nl: 'pk_test_TOSV7Y0lyJd50zQTbVoKkOXK',
      fr: 'pk_test_51JMZ8BK2wRc2E4h0hkDi9h9QPIfcQEiyKzJ7i1TU3lYfHOqNGvvpiH5BtkiYcy17ZJsur2Qb1QY3ft9MXql3OUzG00x13yeaem',
      it: 'pk_test_51JMZ8BK2wRc2E4h0hkDi9h9QPIfcQEiyKzJ7i1TU3lYfHOqNGvvpiH5BtkiYcy17ZJsur2Qb1QY3ft9MXql3OUzG00x13yeaem',
      es: 'pk_test_51JMZ8BK2wRc2E4h0hkDi9h9QPIfcQEiyKzJ7i1TU3lYfHOqNGvvpiH5BtkiYcy17ZJsur2Qb1QY3ft9MXql3OUzG00x13yeaem',
      de: 'pk_test_51JMZ8BK2wRc2E4h0hkDi9h9QPIfcQEiyKzJ7i1TU3lYfHOqNGvvpiH5BtkiYcy17ZJsur2Qb1QY3ft9MXql3OUzG00x13yeaem',
    },
    development: {
      gb: 'pk_test_yM1hmY4bq4AOpQatSsDoaAZL',
      nl: 'pk_test_yM1hmY4bq4AOpQatSsDoaAZL',
      fr: 'pk_test_51JMAZQBGIOYu7H523z0bkIm33fEDkGsOzY98t2UwmiZjZpdKxzHp83eQKcMDoF7AIUeMtP6XyPUk3ZeDICahNyer00xnFyfzeV',
      it: 'pk_test_51JMAZQBGIOYu7H523z0bkIm33fEDkGsOzY98t2UwmiZjZpdKxzHp83eQKcMDoF7AIUeMtP6XyPUk3ZeDICahNyer00xnFyfzeV',
      es: 'pk_test_51JMAZQBGIOYu7H523z0bkIm33fEDkGsOzY98t2UwmiZjZpdKxzHp83eQKcMDoF7AIUeMtP6XyPUk3ZeDICahNyer00xnFyfzeV',
      de: 'pk_test_51JMAZQBGIOYu7H523z0bkIm33fEDkGsOzY98t2UwmiZjZpdKxzHp83eQKcMDoF7AIUeMtP6XyPUk3ZeDICahNyer00xnFyfzeV',
    },
    local: {
      gb: 'pk_test_yM1hmY4bq4AOpQatSsDoaAZL',
      nl: 'pk_test_yM1hmY4bq4AOpQatSsDoaAZL',
      fr: 'pk_test_51JMAZQBGIOYu7H523z0bkIm33fEDkGsOzY98t2UwmiZjZpdKxzHp83eQKcMDoF7AIUeMtP6XyPUk3ZeDICahNyer00xnFyfzeV',
      it: 'pk_test_51JMAZQBGIOYu7H523z0bkIm33fEDkGsOzY98t2UwmiZjZpdKxzHp83eQKcMDoF7AIUeMtP6XyPUk3ZeDICahNyer00xnFyfzeV',
      es: 'pk_test_51JMAZQBGIOYu7H523z0bkIm33fEDkGsOzY98t2UwmiZjZpdKxzHp83eQKcMDoF7AIUeMtP6XyPUk3ZeDICahNyer00xnFyfzeV',
      de: 'pk_test_51JMAZQBGIOYu7H523z0bkIm33fEDkGsOzY98t2UwmiZjZpdKxzHp83eQKcMDoF7AIUeMtP6XyPUk3ZeDICahNyer00xnFyfzeV',
    },
  }

  const key = keys[env][countryCode]

  if (!key) {
    throw new Error(`No stripe key for country ${countryCode} on ${env}`)
  }

  return key
}

const getPcaApiKey = env => {
  const FALLBACK_PCA_KEY = 'UA97-PP22-CG98-HT84'

  switch (env) {
    case 'production':
      return 'DD99-PE44-AT33-BP91'
    case 'staging':
      return `JB29-ZE48-YD34-RF97`
    case 'demo':
      return `UM83-RZ36-UU38-DF46`
    case 'development':
      return FALLBACK_PCA_KEY
    default:
      return FALLBACK_PCA_KEY
  }
}

const getHeapAnalyticsProjectId = env => {
  switch (env) {
    case 'production':
      return '559164788'
    default:
      return '1477149526'
  }
}

const config = {
  ENV: env,
  API_URL: `${getAppUrlForCountry('api', 'gb')}/v1`,
  PLATFORM_API_URL: getAppUrlForCountry('platform-api', 'gb'),
  CONSUMER_APP_URL: getAppUrlForCountry('app', countryCode),
  PCA_API_KEY: getPcaApiKey(env),
  STRIPE_KEY: getStripeKey(env),
  DATO_URL: geDatoUrl(env),
  DATO_TOKEN: '1b4bc963d1e778ce632a606df0fa7a',
  LOCALE: getLanguage(),
  CURRENCY: getCurrency(countryCode),
  COUNTRY: getCountryCode(env),
  LANDING_PAGE_URL: getAppUrlForCountry(null, countryCode),
  FEATURE_FLAGS: JSON.parse(process.env.REACT_APP_FEATURE_FLAGS || '{}'),
  get IS_PLANS_2_INVITE_DISABLED() {
    return false
  },
  get HEAP_ANALYTICS_PROJECT_ID() {
    return getHeapAnalyticsProjectId(env)
  },
}

export default config

export const SUCCESS_ANIMATION_DELAY = 1_500
export const FINANCE_MIN_PRICE = 250_00
export const MIN_PAYMENT_AMOUNT = 1_00
export const MAX_PAYMENT_AMOUNT = 30_000_00
