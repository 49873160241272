import { plansorchestrator } from '@tabeo/platform-api-client'
import { StateFunnel } from '@tabeo/scarf'

type ProgressbarProps = {
  data?: plansorchestrator.ModelsGetSubscriptionsSubscription
}

function Progressbar({ data }: ProgressbarProps) {
  const status = data?.status

  if (!status || !['new', 'sent', 'sign_up'].includes(status)) {
    return null
  }

  let states = ['Sent', 'Sign up', 'Active']
  let currentIndex = 0

  if (status === 'sent') {
    currentIndex = 0
  } else if (status === 'new') {
    states = ['Registered', 'Payment details', 'Active']
    currentIndex = 0
  } else {
    currentIndex = 1
  }

  return (
    <StateFunnel
      states={states}
      pastStepProps={{ bg: 'yellow.5', color: 'yellow.2' }}
      activeStepProps={{ bg: 'yellow.4', color: 'yellow.1' }}
      futureStepProps={{ bg: 'white', color: 'ink.2' }}
      currentIndex={currentIndex}
    />
  )
}

export default Progressbar
