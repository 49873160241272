import { plansorchestrator } from '@tabeo/platform-api-client'
import Active from './Active'
import BulkTransfer from './BulkTransfer'
import Canceled from './Canceled'
import Expired from './Expired'
import New from './New'
import NoDirectDebit from './NoDirectDebit'
import Overdue from './Overdue'
import Pending from './Pending'
import Sent from './Sent'
import SignUp from './SignUp'

type ActionPanelProps = {
  data?: plansorchestrator.ModelsGetSubscriptionsSubscription
}

function ActionPanel({ data }: ActionPanelProps) {
  if (data?.status === 'new') {
    return <New />
  }

  if (data?.status === 'sent') {
    return <Sent />
  }

  if (data?.status === 'sign_up') {
    return <SignUp />
  }

  if (data?.status === 'active') {
    return <Active />
  }

  if (data?.status === 'canceled') {
    return <Canceled />
  }

  if (data?.status === 'pending') {
    return <Pending />
  }

  if (data?.status === 'no_direct_debit') {
    return <NoDirectDebit />
  }

  if (data?.status === 'bulk_transfer') {
    return <BulkTransfer />
  }

  if (data?.status === 'overdue') {
    return <Overdue />
  }

  if (data?.status === 'expired') {
    return <Expired />
  }

  return null
}

export default ActionPanel
